import {Injectable} from "@angular/core";

@Injectable({
	providedIn: 'root'
})
export class LanguagesService {
	languages = [
		{key: 'nl', value: 'Nederlands'},
//		{key: 'en', value: 'English'},
//		{key: 'de', value: 'Deutsch'},
//		{key: 'fr', value: 'Francais'}
	];
	GetLanguages() {
		return this.languages;
	}
	GetLanguage(key: string): string {
		for(let i=0; i<this.languages.length; i++)
			if(this.languages[i].key==key)
				return this.languages[i].value;
		return '';
	}
}
