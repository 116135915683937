import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  environment: any=environment;
  constructor() {}

  @Input() class: string='';
  @Input() module_header: boolean=true;

  ngOnInit(): void {
  }
}
