import {Component, OnInit } from '@angular/core';
import {TokenService} from "../../../../services/auth/token.service";
import {MenuService} from "../../../../services/menu/menu.service";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {PlantsService} from "../../../company/data/plants.service";
import {ReportsService} from "../../data/reports.service";
import {PdfService} from "../../../../services/pdf-make/pdf-service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {HttpParams} from "@angular/common/http";
import {JBMSelectOption} from "../../../../components/JBM/Forms/JBMFormGroups/JBMSelect/JBMSelect.component";

@Component({
  selector: 'production-report',
  templateUrl: './production-report.component.html',
  styleUrls: ['./production-report.component.scss'],
  providers: [PdfService]
})
export class ProductionReportComponent implements OnInit {
  settings: FormGroup;
  plantOptions: JBMSelectOption[]=[];
  template: any={};
  data: any;
  empty: boolean=true;
  modules: any[]=this.TokenService.getAuthorizedModules();
  iconClassname: string=this.MenuService.getMenuItemIconClassname(this.modules, 'reports');

  // Date range filtering
  fromDate: number=0;
  toDate: number=0;

  plant_id: number=0;
  project_id: number=0;
  project_name: string='';
  customer_id: number=0;
  customer_name: string='';
  view: number=0; // 0=Actually produced, 1=Customer produced

  constructor(
      private MenuService: MenuService,
      private TokenService: TokenService,
      public FormBuilder: FormBuilder,
      private TranslateService: TranslateService,
      private PlantsService: PlantsService,
      private ReportsService: ReportsService,
      private PdfService: PdfService
  ) {
    this.settings=this.FormBuilder.group({
      include_totals: new FormControl(true),
      per_recipe: new FormControl(true),
      per_customer: new FormControl(true),
      per_day: new FormControl(true)
    })
  }
  ngOnInit(): void {
    let plants=[];
    this.PlantsService.getData(null).subscribe(
        (data)=>plants=data.data,(error)=>console.error(error),()=>{
          this.plantOptions = this.PlantsService.getSelectOptions(plants);
          this.plantOptions.unshift({ key: '0', value: this.TranslateService.GetTranslation('ui.all')})
          this.getData();
        }
    );
  }
  setDateRange(event: any) {
    this.fromDate=event.fromUnixTime;
    this.toDate=event.toUnixTime;
    this.getData();
  }
  undoDateRange() {
    this.fromDate=0;
    this.toDate=0;
    this.getData();
  }
  selectPlant(event: any) {
    this.plant_id=parseInt(event.target.value);
    this.getData();
  }
  selectCustomer(customer: any) {
    this.customer_id=customer.id;
    this.customer_name='';
    if(customer.id)
      this.customer_name=customer.name;

    this.project_id=0;
    this.project_name='';

    this.getData();
  }
  selectProject(project: any) {
    this.project_id=project.id;
    this.project_name='';
    if(!project.id) return;

    this.project_name=project.name;

    // Reset date range
    this.fromDate=0;
    this.toDate=0;

    // Preset options
    this.settings.controls.include_totals.setValue(false);
    this.settings.controls.per_recipe.setValue(true);
    this.settings.controls.per_day.setValue(true);

    this.getData();
  }
  changeView(event: any) {
    this.view=parseInt(event.target.value);
    this.getData();
  }
  getData() {
    let params=new HttpParams()
        .append('includeTotals',this.settings.controls.include_totals.value===true ? '1' : '0')
        .append('perRecipe',this.settings.controls.per_recipe.value===true ? '1' : '0')
        .append('perCustomer',this.settings.controls.per_customer.value===true ? '1' : '0')
        .append('perDay',this.settings.controls.per_day.value===true ? '1' : '0')
        .append('plant_id',this.plant_id.toString())
        .append('customer_id',this.customer_id.toString())
        .append('project_id', this.project_id.toString())
        .append('view', this.view.toString())
        .append('fromUnixTime', this.fromDate.toString())
        .append('toUnixTime', this.toDate.toString());
    this.ReportsService.getProduction('produced', params).subscribe(
        (data)=>this.data=data,
        (error)=>console.error(error),
        ()=> {

          // Check empty report
          this.empty=this.data.length===0;
          if(this.empty) return;

          this.renderReport();
        }
    )
  }
  renderReport() {
    if(!this.settings.controls.per_customer.value &&
        !this.settings.controls.per_day.value &&
        !this.settings.controls.per_recipe.value)
      // Show only total
      this.settings.controls.include_totals.setValue(true);

    let settings= {
      includeTotals: this.settings.controls.include_totals.value,
      perCustomer: this.settings.controls.per_customer.value,
      perDay: this.settings.controls.per_day.value,
      perRecipe: this.settings.controls.per_recipe.value,
      from: new Date(this.fromDate),
      to: new Date(this.toDate)
    }

    this.composeTemplate(settings);
    this.createPDF()
  }
  composeTemplate(settings) {
    let content=this.composeContent(settings);

    this.template = {
      content:content,
      styles: {
        header: { fontSize: 14, bold: true, margin: [0, 30, 30, 0]},
        subheader: { fontSize: 12, bold: true, margin: [0, 20, 20, 0] },
        small: { fontSize: 8 }
      },
      defaultStyle: {
        fontSize: 10
      }
    };
  }
  composeContent(settings) {
    let content=[];

    content.push({text: this.getTitle(), style: "header"});
    if(this.project_name) content.push({
      text: this.TranslateService.GetTranslation('entity.project') + ': ' + this.project_name,
      style: "subheader"
    });
    if(this.fromDate) content.push(
      {text: this.TranslateService.GetTranslation('ui.from') + ': ' +
      settings.from.toLocaleDateString('nl-NL') + ' ' +
      this.TranslateService.GetTranslation('ui.upto') + ': ' + settings.to.toLocaleDateString('nl-NL')}
    );

    if(!settings.perCustomer && !settings.perDay && !settings.perRecipe) {
      content.push(this.getTotalTable(this.data[0]));
      return content;
    }

    if(settings.includeTotals) {
      content.push({text: this.TranslateService.GetTranslation('ui.totals'), style: "subheader"});
      content.push({text: ' '})
      if((settings.perCustomer && !settings.perDay && !settings.perRecipe) ||
         (!settings.perCustomer && settings.perDay && !settings.perRecipe) ||
         (!settings.perCustomer && !settings.perDay && settings.perRecipe)) {
        content.push(this.getTotalTable(this.data[0]));
      } else {
        if(settings.perCustomer)
          // Per day or per recipe
          content.push(this.getCustomerTotalsTable(this.data));
        else {
          if(settings.perDay)
            content.push(this.getDatesTable(this.data[0].dates));
        }
      }

      content.push({text: ' ', pageBreak: 'after'})
    }

    content.push({text: " "});

    if(settings.perCustomer && !settings.perDay && !settings.perRecipe) {
      content.push(this.getCustomerTotalsTable(this.data));
      content.push({text: " "});
      return content;
    }

    if(settings.perCustomer) {
      for(let section of this.data) {
        if(section.description==='totals') continue; // Skip totals section
        content.push({text: section.description, bold: true, margin: [0, 0, 0, 5]});

        if(settings.perDay && settings.perRecipe) {
          for(const date of section.dates) {
            content.push({text: ' '}); // Extra whitespace
            content.push({text: date.description, margin: [0, 0, 0, 5]});
            content.push(this.getRecipesTable(date.recipes));
          }
        } else if(settings.perDay)
          content.push(this.getDatesTable(section.dates));
        else
          content.push(this.getRecipesTable(section.recipes));
        content.push({text: " "});
      }
      return content;
    }

    if(settings.perDay && settings.perRecipe) {
      for(const date of this.data[0].dates) {
        content.push({text: ' '}); // Extra whitespace
        content.push({text: date.description, margin: [0, 0, 0, 5]});
        content.push(this.getRecipesTable(date.recipes));
      }
    } else if(settings.perDay)
      content.push(this.getDatesTable(this.data[0].dates));
    else
      content.push(this.getRecipesTable(this.data[0].recipes));

    return content;
/*

      if(settings.perCustomer) {
        this.data.sort(compareSection);
        if(settings.perRecipe)
          for(let c of this.data) {
            if(c.description==='totals') continue; // Skip totals section
            content.push({text: c.description, bold: true, margin: [0, 0, 0, 5]});
            content.push(this.getRecipesTable(c.recipes));
          }
        else
          content.push(this.getCustomerTotalsTable(this.data));
        content.push({text: " "})
      } else {
        if(settings.perRecipe)
          content.push(this.getRecipesTable(this.data[0].recipes));
        else
          content.push(this.getTotalTable(this.data[0]));
        content.push({ text:" " });
      }

      if(this.settings.controls.per_customer.value ||
          this.settings.controls.per_day.value ||
          this.settings.controls.per_recipe.value)
          // If minimal one grouping setting is set, the report continues on a new page
        content.push({ text:' ',pageBreak:'after' })
    } else
      content.push({ text: '', style:"subheader" }); // Extra whitespace

    if(settings.perDay) {
      if(settings.perCustomer) {
        for(let c of this.data) {
          if(c.description==='totals') continue; // Skip totals section
          content.push({ text: c.description, bold: true, margin: [0,0,0,5] });
          if(settings.perRecipe)
            for(const d of c.dates) {
              content.push({ text: ' ' }); // Extra whitespace
              content.push({ text: d.description, margin: [0,0,0,5] });
              content.push(this.getRecipesTable(d.recipes));
            }
          else
            content.push(this.getDatesTable(c.dates));
        }
      } else {
        if(settings.perRecipe)
          for(const c of this.data[0].dates) {
            content.push({ text: c.description, bold: true, margin: [0,0,0,5] });
            content.push(this.getRecipesTable(c.recipes));
            content.push({ text:" " });
          }
        else
          content.push(this.getDatesTable(this.data[0].dates));
      }
    } else
    if(settings.perCustomer)
      content.push(this.getCustomerTotalsTable(this.data));

    return content;*/
  }
  getTitle() {
    let title=this.TranslateService.GetTranslation('menu.production') + this.TranslateService.GetTranslation('reports.report').toLowerCase();
    if(!this.plant_id) return title;

    for(let option of this.plantOptions)
      if(this.plant_id.toString()===option.key) {
        title+=' (' + option.value + ')';
        break;
      }

    return title;
  }
  getRecipesTable(data){
    data.sort( (a,b) => { return b.total - a.total } );
    let body = [[
        { text: this.TranslateService.GetTranslation('entity.recipe') + ' ' +
            this.TranslateService.GetTranslation('ui.number').toLowerCase() + ' / ' +
            this.TranslateService.GetTranslation('entity.name') + ' / ' +
            this.TranslateService.GetTranslation('entity.notes'),
            alignment:'left', bold:true },
        { text: this.TranslateService.GetTranslation('production.produced'), bold:true }
    ]]
    for(let r of data) {
      body.push([
        { text :r.description + ((r.internal.trim()!=='') ? " / " + r.internal : ''), bold: false },
        { text : r.total.toFixed(2).replace('.',',') +' m3', bold: false, alignment:'right' }
      ])
    }
    return { table : { widths: ['*', 'auto'], body: body } }
  }
  getDatesTable(data){
    let body = [[
      { text: this.TranslateService.GetTranslation('datetime.date'), alignment:'left', bold:true },
      { text: this.TranslateService.GetTranslation('production.produced'), bold:true }
    ]]
    for(let d of data) {
      body.push([
        { text : d.description, bold: false },
        { text : d.total.toFixed(2).replace('.',',') +' m3', bold: false, alignment:'right' }
      ])
    }
    return { table : { widths: ['*', 'auto'], body: body } }
  }
  getCustomerTotalsTable(data) {
    let body = [[
      { text: this.TranslateService.GetTranslation('menu.company'), alignment:'left', bold:true },
      { text: this.TranslateService.GetTranslation('production.produced'), bold:true }
    ]]
    for(let d of data) {
      if(d.description==='totals')  // Skip totals section
        continue;
      body.push([
        { text : d.description, bold: false },
        { text : d.total.toFixed(2).replace('.',',') +' m3', bold: false, alignment:'right' }
      ])
    }
    return { table : { widths: ['*', 'auto'], body: body } }
  }
  getTotalTable(data) {
    console.log(data.total);
    let body = [[
      { text: this.TranslateService.GetTranslation('production.produced'), bold:true }
    ]]
    body.push([
      {text: data.total.toFixed(2).replace('.', ',') + ' m3', bold: false}
    ])
    return { table : { widths: ['auto'], body: body } }
  }
  createPDF() {
    this.PdfService.loadPdfMaker().then(unfulfillment => {
          this.PdfService.pdfMake.createPdf(this.template).getDataUrl(function(outDoc) {
            (document.getElementById('pdfObject') as HTMLObjectElement).data=outDoc;
          });
        }
    );
  }
}

function compareSection(a, b) {
  if (a.description > b.description) return 1;
  return 0;
}
