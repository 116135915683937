<div class="sidebar" (mouseenter)="expand()" (mouseleave)="collapse()">
  <div class="company">
    <a routerLink="/" routerLinkActive="active">
      <img src="assets/img/logo.png" alt="JBM Logo">
    </a>
  </div>
  <div class="toggle-container">
    <div class="toggler" (click)="toggleSidebar()" placement="right" container="body" [ngbTooltip]="tooltip"></div>
  </div>
  <div class="menuitems-container">
    <div class="menuitems">
      <div class="menuitem-container clearfix">
          <a class="link" routerLink="/dashboard" routerLinkActive="active">
            <i class="fas fa-tachometer-alt"></i>
            <span>{{"menu.dashboard"|translate}}</span>
          </a>
          <a class="new-tab" routerLink="/dashboard" target="_blank">
            <i class="fa fa-external-link-alt"></i>
          </a>
      </div>
      <div *ngFor="let menuitem of menu" class="menuitem-container clearfix">
        <a class="link" routerLink="/{{menuitem.url}}" routerLinkActive="active">
          <i class="fas fa-{{menuitem.icon_class}}"></i>
          <span>{{menuitem.header|translate}}</span>
        </a>
        <a class="new-tab" routerLink="/{{menuitem.url}}" target="_blank">
          <i class="fa fa-external-link-alt"></i>
        </a>
      </div>
    </div>
  </div>
  <div class="user">
    <div class="menuitems">
       <div class="menuitem-container clearfix">
        <a class="link" (click)="toggleDarkMode();$event.stopPropagation()">
           <i *ngIf="darkMode" class="fa fa-sun"></i>
           <span *ngIf="darkMode">{{"ui.theme.light"|translate}}</span>
           <i *ngIf="!darkMode" class="fa fa-moon"></i>
           <span *ngIf="!darkMode">{{"ui.theme.dark"|translate}}</span>
        </a>
      </div>
      <div class="menuitem-container clearfix">
        <a class="link" (click)="shortCuts();$event.stopPropagation()">
          <i class="fas fa-keyboard"></i>
          <span>{{"ui.shortcuts"|translate}}</span>
        </a>
      </div>
      <div class="menuitem-container clearfix">
        <a class="link" (click)="logOut()">
          <i class="fas fa-sign-out-alt"></i>
          <span>{{"user.logout"|translate}}</span>
        </a>
      </div>
    </div>
  </div>
</div>
